export default {
  cs: {
    locales: {
      cs: 'Česky',
      sk: 'Slovensky',
      en: 'Anglicky',
      de: 'Německy',
    },
    action: {
      back: 'Zpět',
      search: 'Vyhledat',
      save: 'Uložit',
      open: 'Otevřít',
      close: 'Zavřit',
      cancel: 'Zrušit',
      continue: 'Pokračovat',
      confirm: 'Potvrdit',
      reject: 'Odmítnout',
      edit: 'Upravit',
      ignore: 'Ignorovat',
      create: 'Vytvořit',
      activate: 'Aktivovat',
      deactivate: 'Deaktivovat',
      archive: 'Archivovat',
      generate: 'Vygenerovat',
      download: 'Stáhnout',
      export: 'Exportovat',
      add: 'Přidat',
      import: 'Importovat',
      filter: 'Filtrovat',
      detail: 'Detail',
      remove: 'Odstranit',
    },
    common: {
      no: 'Ne',
      yes: 'Ano',
      from: 'od',
      to: 'do',
      by: 'z',
      or: 'nebo',
      points: '{n} bodů | {n} bod | {n} body | {n} bodů',
      pointsShort: '{n} b. | {n} b. | {n} b. | {n} b.',
      recordSaved: 'Záznam byl úspěšně uložen',
      recordSaveFailed: 'Při ukládání záznamu došlo k chybě',
      optionsAreLoading: 'Možnosti se načítají...',
      optionsLoadFailed: 'Chyba stahování',
      noOptions: 'Žádná data',
      noData: 'Žádná data',
      actionSuccess: 'Akce byla úspěšně provedena',
      actionCanceled: 'Akce byl zrušena',
    },
    layout: {
      components: {
        aside: {
          menu: {
            applications: 'Další aplikace',
            changeLocale: 'Změnit jazyk',
          },
        },
        detailSummary: {
          collapseTriggerOn: 'Více informací',
          collapseTriggerOff: 'Méně informací',
        },
        user: {
          logout: 'Odhlásit se',
        },
        monacoEditor: {
          loading: 'Editor se načítá...',
        },
        crashState: {
          title: 'Pád aplikace',
          body: 'Došlo k pádu aplikace, zkuste ji obnovit',
          refresh: 'Obnovit',
        },
        startup: {
          title: 'Aplikace se načítá',
        },
      },
    },
    actionBar: {
      moreActions: 'Další akce',
    },
    grid: {
      noResults: 'Žádné výsledky',
      actions: '',
      advancedFilters: {
        reset: 'Resetovat',
        use: 'Použít filtry',
      },
      selectedRows: {
        anyOnCurrentPage: {
          note: 'Jsou vybrány některé záznamy na této stránce ({itemCount}).',
          pickAllOnCurrentPage: 'Vybrat celou stránku',
          deselectAllRows: 'Zrušit výběr',
        },
        allOnCurrentPage: {
          note: 'Jsou vybrány všechny záznamy na této stránce ({itemCount}).',
          pickAll: 'Vybrat všechny záznamy',
          deselectAllRows: 'Zrušit výběr',
        },
        all: {
          note: 'Jsou vybrány všechny záznamy',
          pickAllOnCurrentPage: 'Vybrat pouze aktuální stránku',
          deselectAllRows: 'Zrušit výběr',
        },
      },
      bulkActions: {
        select: {
          placeholder: 'Vybrat hromadnou akci',
        },
      },
      export: {
        fileTypes: {
          csv: 'Export do CSV',
          xlsx: 'Export do XLSX',
        },
      },
    },
    form: {
      asyncInput: {
        loading: 'Políčko se načítá',
        error: 'Při načítání políčka došlo k chybě',
      },
      fileInput: {
        placeholder: 'Vyberte soubor',
        field: 'Nahrát soubor',
      },
      jsonInput: {
        helpModal: {
          title: 'Nápověda editoru',
          common: {
            heading: 'Obecné',
            text: 'Upravovat konfiguraci můžete pomocí integrovaného textového editoru. Editor nabízí základní nástroje pro jednodušší práci s JSON souberem.',
          },
          shortcuts: {
            heading: 'Klávesové zkratky',
            ctrlSpace: {
              keyBind: 'Ctrl + Mezerník',
              description: 'Zobrazení našeptávače přímo v místě, kde máte umístěný kurzor. Našeptávač zobrazí možnosti, které lze rychle zadat do dané části konfigurace,',
            },
            ctrlF: {
              keyBind: 'Ctrl + F',
              description: 'Zobrazení vyhledávací pole. Zadejte hledaný text a následně se můžete rychle přepínat mezi nalezenými částmi textu.',
            },
          },
        },
      },
    },
    schemaForms: {
      action: {
        collapseAll: 'Sbalit vše',
        expandAll: 'Rozbalit vše',
      },
      selectNode: 'Vyberte jednu ze sekcí',
      nodes: {
        suggestions: {
          example: 'Příklad',
          use: 'Použít',
        },
        inputString: {
          manualInput: 'Ruční zadání',
        },
        object: {
          placeholders: {
            add: 'Zadejte název atributu',
          },
        },
        array: {
          placeholders: {
            add: 'Zadejte název atributu',
          },
        },
        oneOf: {
          placeholders: {
            add: 'Zadejte název atributu',
          },
        },
        field: {
          booleanEnum: {
            true: 'Ano',
            false: 'Ne',
          },
        },
        tree: {
          filledNodesOnly: {
            button: {
              title: 'Zobrazit pouze vyplněné položky',
            },
          },
          devMode: {
            button: {
              title: 'Zobrazit vývojový režim',
            },
          },
        },
      },
      modals: {
        newNodeName: {
          message: 'Zadejte klíč nového atributu',
        },
        wrongNewNodeName: {
          message: 'Klíč atributu nemůže být prázdný',
        },
        confirmRemoveUpToRoot: {
          message: 'Opravdu chcete odstranit celý uzel "{name}"?',
        },
        confirmRemoveArrayItem: {
          message: 'Opravdu chcete odstranit položku "{name}"?',
        },
        confirmRemoveObjectProperty: {
          message: 'Opravdu chcete odstranit položku "{name}"?',
        },
      },
    },
    // yup i18n translation matrix is here packages/ui/plugins/i18n.ts:112
    validation: {
      default: 'Políčko "{field}" není vyplněno správně',
      required: 'Políčko "{field}" je povinné',
      invalidDateForm: 'Platný formát datumu',
      dateMin: 'Zadané datum nesmí být menší než {min}',
      dateMax: 'Zadané datum nesmí být větší než {max}',
      invalid: 'Políčko "{field}" má neplatnou hodnotu',
      invalidJson: 'Neplatný formát JSON',
      email: 'Zadaný email není platný',
      phone: 'Telefonní číslo musí začínat na předvolby +420, +421, +48, +43, +49, +38 nebo +41 a nesmí obsahovat mezery',
      file: {required: 'Soubor je povinný'},
      stringMin: 'Minimální délka je {min} znaků',
      stringMax: 'Maximální délka je {max} znaků',
      integer: 'Zadaná hodnota nesmí být desetinné číslo',
      positiveNumber: 'Zadaná hodnota není kladné číslo',
    },
    notFound: {
      title: 'Zákaznický systém Posigneo',
      subtitle: '404, stránku se nepodařilo nalézt',
      info: 'Nedaří se nám najít požadovanou stránku. Pokud jste nezadávali URL stránky do adresního řádku prohlížeče, máme pravděpodobně chybu v systému. Dejte nám o ní vědět.',
      link: 'Přejít na Dashboard',
    },
    unauthorized: {
      title: 'Zákaznický systém Posigneo',
      subtitle: 'Nemáte dostatečná oprávnění',
      info: 'Omlouváme se, ale požadovanou akci nemůžeme vykonat, protože na ní nemáte dostatečné oprávnění.',
      link: 'Přejít na Dashboard',
    },
  },
  sk: {
    locales: {
      cs: 'Česky',
      sk: 'Slovensky',
      en: 'Anglicky',
      de: 'Nemecky',
    },
    action: {
      back: 'Späť',
      search: 'Hľadať',
      save: 'Uložiť',
      open: 'Otvoriť',
      close: 'Zatvoriť',
      cancel: 'Zrušiť',
      continue: 'Pokračovať',
      confirm: 'Potvrdiť',
      reject: 'Odmietnuť',
      edit: 'Upraviť',
      ignore: 'Ignorovať',
      create: 'Vytvoriť',
      activate: 'Aktivovať',
      deactivate: 'Deaktivovať',
      archive: 'Archivovať',
      generate: 'Generovať',
      download: 'Stiahnuť',
      export: 'Exportovať',
      add: 'Pridať',
      import: 'Importovať',
      filter: 'Filtrovať',
      detail: 'Detail',
      remove: 'Odstrániť',
    },
    common: {
      no: 'Nie',
      yes: 'Áno',
      from: 'od',
      to: 'do',
      by: 'z',
      or: 'alebo',
      points: '{n} bodov | {n} bod | {n} body | {n} bodov',
      pointsShort: '{n} b. | {n} b. | {n} b. | {n} b.',
      recordSaved: 'Záznam bol úspešne uložený',
      recordSaveFailed: 'Pri ukladaní záznamu sa vyskytla chyba',
      optionsAreLoading: 'Možnosti sa načítavajú...',
      optionsLoadFailed: 'Chyba pri načítavaní možností',
      noOptions: 'Žiadne údaje',
      noData: 'Žiadne údaje',
      actionSuccess: 'Akcia bola úspešná',
      actionCanceled: 'Akcia bola zrušená',
    },
    layout: {
      components: {
        aside: {
          menu: {
            applications: 'Ďalšie aplikácie',
            changeLocale: 'Zmeniť jazyk',
          },
        },
        detailSummary: {
          collapseTriggerOn: 'Viac informácií',
          collapseTriggerOff: 'Menej informácií',
        },
        user: {
          logout: 'Odhlásiť sa',
        },
        monacoEditor: {
          loading: 'Editor sa načíta...',
        },
        crashState: {
          title: 'Zlyhanie aplikácie',
          body: 'Aplikácia zlyhala, skúste ju obnoviť',
          refresh: 'Obnoviť',
        },
        startup: {
          title: 'Aplikácia sa načíta',
        },
      },
    },
    actionBar: {
      moreActions: 'Viac akcií',
    },
    grid: {
      noResults: 'Žiadne výsledky',
      actions: '',
      advancedFilters: {
        reset: 'Obnoviť',
        use: 'Použiť filtre',
      },
      selectedRows: {
        anyOnCurrentPage: {
          note: 'Na tejto stránke sú vybrané niektoré záznamy ({itemCount}).',
          pickAllOnCurrentPage: 'Vybrať celú stránku',
          deselectAllRows: 'Zrušiť výber',
        },
        allOnCurrentPage: {
          note: 'Na tejto stránke sú vybrané všetky záznamy ({itemCount}).',
          pickAll: 'Vybrať všetky záznamy',
          deselectAllRows: 'Zrušiť výber',
        },
        all: {
          note: 'Všetky záznamy sú vybrané',
          pickAllOnCurrentPage: 'Vybrať len aktuálnu stránku',
          deselectAllRows: 'Zrušiť výber',
        },
      },
      bulkActions: {
        select: {
          placeholder: 'Vybrať hromadnú akciu',
        },
      },
      export: {
        fileTypes: {
          csv: 'Exportovať do CSV',
          xlsx: 'Exportovať do XLSX',
        },
      },
    },
    form: {
      asyncInput: {
        loading: 'Pole sa načíta',
        error: 'Pri načítavaní poľa sa vyskytla chyba',
      },
      fileInput: {
        placeholder: 'Vybrať súbor',
        field: 'Nahrať súbor',
      },
      jsonInput: {
        helpModal: {
          title: 'Pomocník editora',
          common: {
            heading: 'Všeobecné',
            text: 'Konfiguráciu môžete upravovať pomocou integrovaného textového editora. Editor ponúka základné nástroje pre jednoduchšiu prácu s JSON súborom.',
          },
          shortcuts: {
            heading: 'Klávesové skratky',
            ctrlSpace: {
              keyBind: 'Ctrl + Medzerník',
              description: 'Zobrazenie automatického dopĺňania priamo na mieste, kde je umiestnený váš kurzor. Automatické dopĺňanie zobrazí možnosti, ktoré možno rýchlo zadať do príslušnej časti konfigurácie.',
            },
            ctrlF: {
              keyBind: 'Ctrl + F',
              description: 'Zobrazenie vyhľadávacieho poľa. Zadajte hľadaný text a potom sa môžete rýchlo prepínať medzi nájdenými časťami textu.',
            },
          },
        },
      },
    },
    schemaForms: {
      action: {
        collapseAll: 'Zbaliť všetko',
        expandAll: 'Rozbaliť všetko',
      },
      selectNode: 'Vyberte jednu zo sekcií',
      nodes: {
        suggestions: {
          example: 'Príklad',
          use: 'Použiť',
        },
        inputString: {
          manualInput: 'Manuálne zadanie',
        },
        object: {
          placeholders: {
            add: 'Zadajte názov atribútu',
          },
        },
        array: {
          placeholders: {
            add: 'Zadajte názov atribútu',
          },
        },
        oneOf: {
          placeholders: {
            add: 'Zadajte názov atribútu',
          },
        },
        field: {
          booleanEnum: {
            true: 'Áno',
            false: 'Nie',
          },
        },
        tree: {
          filledNodesOnly: {
            button: {
              title: 'Zobraziť iba vyplnené položky',
            },
          },
          devMode: {
            button: {
              title: 'Zobraziť vývojový režim',
            },
          },
        },
      },
      modals: {
        newNodeName: {
          message: 'Zadajte kľúč nového atribútu',
        },
        wrongNewNodeName: {
          message: 'Kľúč atribútu nesmie byť prázdny',
        },
        confirmRemoveUpToRoot: {
          message: 'Naozaj chcete odstrániť celý uzol "{name}"?',
        },
        confirmRemoveArrayItem: {
          message: 'Naozaj chcete odstrániť položku "{name}"?',
        },
        confirmRemoveObjectProperty: {
          message: 'Naozaj chcete odstrániť položku "{name}"?',
        },
      },
    },
    validation: {
      default: 'Pole "{field}" nie je správne vyplnené',
      required: 'Pole "{field}" je povinné',
      invalidDateForm: 'Platný formát dátumu',
      dateMin: 'Zadaný dátum nesmie byť skôr ako {min}',
      dateMax: 'Zadaný dátum nesmie byť neskôr ako {max}',
      invalid: 'Pole "{field}" má neplatnú hodnotu',
      invalidJson: 'Neplatný formát JSON',
      email: 'Zadaný e-mail nie je platný',
      phone: 'Telefónne číslo musí začínať predvoľbami +420, +421, +48, +43, +49, +38 alebo +41 a nesmie obsahovať medzery',
      file: {required: 'Súbor je povinný'},
      stringMin: 'Minimálna dĺžka je {min} znakov',
      stringMax: 'Maximálna dĺžka je {max} znakov',
      integer: 'Zadaná hodnota nesmie byť desatinné číslo',
      positiveNumber: 'Zadaná hodnota nie je kladné číslo',
    },
    notFound: {
      title: 'Zákaznícky systém Posigneo',
      subtitle: '404, stránka sa nenašla',
      info: 'Nemôžeme nájsť požadovanú stránku. Ak ste nezadali URL stránky do adresného riadku prehliadača, pravdepodobne máme chybu v systéme. Dajte nám o tom vedieť.',
      link: 'Prejsť na Dashboard',
    },
    unauthorized: {
      title: 'Zákaznícky systém Posigneo',
      subtitle: 'Nemáte dostatočné oprávnenia',
      info: 'Ospravedlňujeme sa, ale požadovanú akciu nemôžeme vykonať, pretože na ňu nemáte dostatočné oprávnenia.',
      link: 'Prejsť na Dashboard',
    },
  },
  en: {
    locales: {
      cs: 'Czech',
      sk: 'Slovak',
      en: 'English',
      de: 'German',
    },
    action: {
      back: 'Back',
      search: 'Search',
      save: 'Save',
      open: 'Open',
      close: 'Close',
      cancel: 'Cancel',
      continue: 'Continue',
      confirm: 'Confirm',
      reject: 'Reject',
      edit: 'Edit',
      ignore: 'Ignore',
      create: 'Create',
      activate: 'Activate',
      deactivate: 'Deactivate',
      archive: 'Archive',
      generate: 'Generate',
      download: 'Download',
      export: 'Export',
      add: 'Add',
      import: 'Import',
      filter: 'Filter',
      detail: 'Detail',
      remove: 'Remove',
    },
    common: {
      no: 'No',
      yes: 'Yes',
      from: 'from',
      to: 'to',
      by: 'by',
      or: 'or',
      points: '{n} points | {n} point | {n} points | {n} points',
      pointsShort: '{n} pts. | {n} pt. | {n} pts. | {n} pts.',
      recordSaved: 'Record has been successfully saved',
      recordSaveFailed: 'Error occurred while saving the record',
      optionsAreLoading: 'Options are loading...',
      optionsLoadFailed: 'Error loading options',
      noOptions: 'No data',
      noData: 'No data',
      actionSuccess: 'Action was successful',
      actionCanceled: 'Action was canceled',
    },
    layout: {
      components: {
        aside: {
          menu: {
            applications: 'More Applications',
            changeLocale: 'Change Language',
          },
        },
        detailSummary: {
          collapseTriggerOn: 'More Information',
          collapseTriggerOff: 'Less Information',
        },
        user: {
          logout: 'Logout',
        },
        monacoEditor: {
          loading: 'Editor is loading...',
        },
        crashState: {
          title: 'Application Crash',
          body: 'The application has crashed, try to refresh it',
          refresh: 'Refresh',
        },
        startup: {
          title: 'Application is loading',
        },
      },
    },
    actionBar: {
      moreActions: 'More Actions',
    },
    grid: {
      noResults: 'No results',
      actions: '',
      advancedFilters: {
        reset: 'Reset',
        use: 'Apply Filters',
      },
      selectedRows: {
        anyOnCurrentPage: {
          note: 'Some records on this page are selected ({itemCount}).',
          pickAllOnCurrentPage: 'Select entire page',
          deselectAllRows: 'Deselect all',
        },
        allOnCurrentPage: {
          note: 'All records on this page are selected ({itemCount}).',
          pickAll: 'Select all records',
          deselectAllRows: 'Deselect all',
        },
        all: {
          note: 'All records are selected',
          pickAllOnCurrentPage: 'Select only current page',
          deselectAllRows: 'Deselect all',
        },
      },
      bulkActions: {
        select: {
          placeholder: 'Select bulk action',
        },
      },
      export: {
        fileTypes: {
          csv: 'Export to CSV',
          xlsx: 'Export to XLSX',
        },
      },
    },
    form: {
      asyncInput: {
        loading: 'Field is loading',
        error: 'Error occurred while loading the field',
      },
      fileInput: {
        placeholder: 'Select file',
        field: 'Upload file',
      },
      jsonInput: {
        helpModal: {
          title: 'Editor Help',
          common: {
            heading: 'General',
            text: 'You can edit the configuration using the integrated text editor. The editor offers basic tools for easier work with the JSON file.',
          },
          shortcuts: {
            heading: 'Keyboard Shortcuts',
            ctrlSpace: {
              keyBind: 'Ctrl + Space',
              description: 'Display the autocompletion directly where your cursor is placed. The autocompletion will show options that can be quickly entered into the configuration section.',
            },
            ctrlF: {
              keyBind: 'Ctrl + F',
              description: 'Display the search field. Enter the search text, and then you can quickly switch between the found parts of the text.',
            },
          },
        },
      },
    },
    schemaForms: {
      action: {
        collapseAll: 'Collapse All',
        expandAll: 'Expand All',
      },
      selectNode: 'Select one of the sections',
      nodes: {
        suggestions: {
          example: 'Example',
          use: 'Use',
        },
        inputString: {
          manualInput: 'Manual Input',
        },
        object: {
          placeholders: {
            add: 'Enter attribute name',
          },
        },
        array: {
          placeholders: {
            add: 'Enter attribute name',
          },
        },
        oneOf: {
          placeholders: {
            add: 'Enter attribute name',
          },
        },
        field: {
          booleanEnum: {
            true: 'Yes',
            false: 'No',
          },
        },
        tree: {
          filledNodesOnly: {
            button: {
              title: 'Show only filled items',
            },
          },
          devMode: {
            button: {
              title: 'Show development mode',
            },
          },
        },
      },
      modals: {
        newNodeName: {
          message: 'Enter the key of the new attribute',
        },
        wrongNewNodeName: {
          message: 'Attribute key cannot be empty',
        },
        confirmRemoveUpToRoot: {
          message: 'Do you really want to remove the entire node "{name}"?',
        },
        confirmRemoveArrayItem: {
          message: 'Do you really want to remove item "{name}"?',
        },
        confirmRemoveObjectProperty: {
          message: 'Do you really want to remove item "{name}"?',
        },
      },
    },
    validation: {
      default: 'Field "{field}" is not filled in correctly',
      required: 'Field "{field}" is required',
      invalidDateForm: 'Valid date format',
      dateMin: 'Entered date must not be earlier than {min}',
      dateMax: 'Entered date must not be later than {max}',
      invalid: 'Field "{field}" has an invalid value',
      invalidJson: 'Invalid JSON format',
      email: 'Entered email is not valid',
      phone: 'Phone number must start with prefixes +420, +421, +48, +43, +49, +38, or +41 and must not contain spaces',
      file: {required: 'File is required'},
      stringMin: 'Minimum length is {min} characters',
      stringMax: 'Maximum length is {max} characters',
      integer: 'Entered value must not be a decimal number',
      positiveNumber: 'Entered value is not a positive number',
    },
    notFound: {
      title: 'Posigneo Customer System',
      subtitle: '404, page not found',
      info: 'We can\'t find the requested page. If you didn\'t enter the URL of the page into the browser\'s address bar, we probably have a system error. Let us know about it.',
      link: 'Go to Dashboard',
    },
    unauthorized: {
      title: 'Posigneo Customer System',
      subtitle: 'You do not have sufficient permissions',
      info: 'We apologize, but we cannot perform the requested action because you do not have sufficient permissions for it.',
      link: 'Go to Dashboard',
    },
  },
  de: {
    locales: {
      cs: 'Tschechisch',
      sk: 'Slowakisch',
      en: 'Englisch',
      de: 'Deutsch',
    },
    action: {
      back: 'Zurück',
      search: 'Suchen',
      save: 'Speichern',
      open: 'Öffnen',
      close: 'Schließen',
      cancel: 'Abbrechen',
      continue: 'Fortsetzen',
      confirm: 'Bestätigen',
      reject: 'Ablehnen',
      edit: 'Bearbeiten',
      ignore: 'Ignorieren',
      create: 'Erstellen',
      activate: 'Aktivieren',
      deactivate: 'Deaktivieren',
      archive: 'Archivieren',
      generate: 'Generieren',
      download: 'Herunterladen',
      export: 'Exportieren',
      add: 'Hinzufügen',
      import: 'Importieren',
      filter: 'Filtern',
      detail: 'Detail',
      remove: 'Entfernen',
    },
    common: {
      no: 'Nein',
      yes: 'Ja',
      from: 'von',
      to: 'bis',
      by: 'von',
      or: 'oder',
      points: '{n} Punkte | {n} Punkt | {n} Punkte | {n} Punkte',
      pointsShort: '{n} P. | {n} P. | {n} P. | {n} P.',
      recordSaved: 'Datensatz wurde erfolgreich gespeichert',
      recordSaveFailed: 'Fehler beim Speichern des Datensatzes',
      optionsAreLoading: 'Optionen werden geladen...',
      optionsLoadFailed: 'Fehler beim Laden der Optionen',
      noOptions: 'Keine Daten',
      noData: 'Keine Daten',
      actionSuccess: 'Aktion wurde erfolgreich durchgeführt',
      actionCanceled: 'Aktion wurde abgebrochen',
    },
    layout: {
      components: {
        aside: {
          menu: {
            applications: 'Weitere Anwendungen',
            changeLocale: 'Sprache ändern',
          },
        },
        detailSummary: {
          collapseTriggerOn: 'Mehr Informationen',
          collapseTriggerOff: 'Weniger Informationen',
        },
        user: {
          logout: 'Abmelden',
        },
        monacoEditor: {
          loading: 'Editor wird geladen...',
        },
        crashState: {
          title: 'Anwendungsabsturz',
          body: 'Die Anwendung ist abgestürzt, versuchen Sie, sie neu zu laden',
          refresh: 'Neu laden',
        },
        startup: {
          title: 'Anwendung wird gestartet',
        },
      },
    },
    actionBar: {
      moreActions: 'Weitere Aktionen',
    },
    grid: {
      noResults: 'Keine Ergebnisse',
      actions: '',
      advancedFilters: {
        reset: 'Zurücksetzen',
        use: 'Filter verwenden',
      },
      selectedRows: {
        anyOnCurrentPage: {
          note: 'Einige Datensätze auf dieser Seite ausgewählt ({itemCount}).',
          pickAllOnCurrentPage: 'Alle auf dieser Seite auswählen',
          deselectAllRows: 'Auswahl aufheben',
        },
        allOnCurrentPage: {
          note: 'Alle Datensätze auf dieser Seite ausgewählt ({itemCount}).',
          pickAll: 'Alle auswählen',
          deselectAllRows: 'Auswahl aufheben',
        },
        all: {
          note: 'Alle Datensätze ausgewählt',
          pickAllOnCurrentPage: 'Nur aktuelle Seite auswählen',
          deselectAllRows: 'Auswahl aufheben',
        },
      },
      bulkActions: {
        select: {
          placeholder: 'Massenaktion auswählen',
        },
      },
      export: {
        fileTypes: {
          csv: 'Export als CSV',
          xlsx: 'Export als XLSX',
        },
      },
    },
    form: {
      asyncInput: {
        loading: 'Feld wird geladen',
        error: 'Fehler beim Laden des Felds',
      },
      fileInput: {
        placeholder: 'Datei auswählen',
        field: 'Datei hochladen',
      },
      jsonInput: {
        helpModal: {
          title: 'Editor-Hilfe',
          common: {
            heading: 'Allgemein',
            text: 'Die Konfiguration kann mithilfe des integrierten Texteditors bearbeitet werden. Der Editor bietet grundlegende Tools zur einfacheren Arbeit mit JSON-Dateien.',
          },
          shortcuts: {
            heading: 'Tastenkombinationen',
            ctrlSpace: {
              keyBind: 'Strg + Leertaste',
              description: 'Anzeige des Autovervollständigungs-Menüs am Cursorstandort. Das Menü zeigt Optionen an, die schnell in den Konfigurationsbereich eingegeben werden können.',
            },
            ctrlF: {
              keyBind: 'Strg + F',
              description: 'Anzeige des Suchfelds. Geben Sie den zu suchenden Text ein und wechseln Sie dann schnell zwischen den gefundenen Textabschnitten.',
            },
          },
        },
      },
    },
    schemaForms: {
      action: {
        collapseAll: 'Alle minimieren',
        expandAll: 'Alle erweitern',
      },
      selectNode: 'Wählen Sie einen Abschnitt aus',
      nodes: {
        suggestions: {
          example: 'Beispiel',
          use: 'Verwenden',
        },
        inputString: {
          manualInput: 'Manuelle Eingabe',
        },
        object: {
          placeholders: {
            add: 'Attributnamen eingeben',
          },
        },
        array: {
          placeholders: {
            add: 'Attributnamen eingeben',
          },
        },
        oneOf: {
          placeholders: {
            add: 'Attributnamen eingeben',
          },
        },
        field: {
          booleanEnum: {
            true: 'Ja',
            false: 'Nein',
          },
        },
        tree: {
          filledNodesOnly: {
            button: {
              title: 'Nur gefüllte Elemente anzeigen',
            },
          },
          devMode: {
            button: {
              title: 'Entwicklungsmodus anzeigen',
            },
          },
        },
      },
      modals: {
        newNodeName: {
          message: 'Geben Sie den Schlüssel des neuen Attributes ein',
        },
        wrongNewNodeName: {
          message: 'Der Schlüssel des Attributes darf nicht leer sein',
        },
        confirmRemoveUpToRoot: {
          message: 'Möchten Sie den gesamten Knoten "{name}" wirklich entfernen?',
        },
        confirmRemoveArrayItem: {
          message: 'Möchten Sie den Eintrag "{name}" wirklich entfernen?',
        },
        confirmRemoveObjectProperty: {
          message: 'Möchten Sie den Eintrag "{name}" wirklich entfernen?',
        },
      },
    },
    validation: {
      default: 'Feld "{field}" ist nicht korrekt ausgefüllt',
      required: 'Feld "{field}" ist erforderlich',
      invalidDateForm: 'Ungültiges Datumsformat',
      dateMin: 'Das eingegebene Datum darf nicht vor {min} liegen',
      dateMax: 'Das eingegebene Datum darf nicht nach {max} liegen',
      invalid: 'Feld "{field}" enthält einen ungültigen Wert',
      invalidJson: 'Ungültiges JSON-Format',
      email: 'Die eingegebene E-Mail ist ungültig',
      phone: 'Die Telefonnummer muss mit den Vorwahlen +420, +421, +48, +43, +49, +38 oder +41 beginnen und darf keine Leerzeichen enthalten',
      file: {required: 'Datei ist erforderlich'},
      stringMin: 'Die Mindestlänge beträgt {min} Zeichen',
      stringMax: 'Die Maximallänge beträgt {max} Zeichen',
      integer: 'Der eingegebene Wert darf keine Dezimalstellen enthalten',
      positiveNumber: 'Der eingegebene Wert ist keine positive Zahl',
    },
    notFound: {
      title: 'Posigneo Customer System',
      subtitle: '404, Seite nicht gefunden',
      info: 'Die angeforderte Seite konnte nicht gefunden werden. Wenn Sie die URL nicht manuell eingegeben haben, liegt wahrscheinlich ein Systemfehler vor. Lassen Sie es uns wissen.',
      link: 'Zum Dashboard gehen',
    },
    unauthorized: {
      title: 'Posigneo Customer System',
      subtitle: 'Unzureichende Berechtigungen',
      info: 'Entschuldigen Sie, aber wir können die angeforderte Aktion nicht ausführen, da Ihnen die erforderlichen Berechtigungen fehlen.',
      link: 'Zum Dashboard gehen',
    },
  },
};
