import {VoucherEmissionType} from '@api/posMiddlewareCore/constants/voucherEmissionType';

export default {
  cs: {
    voucherEmission: {
      detail: {
        title: 'Emise - {name}',
        actions: {
          generateVouchers: {
            title: 'Založit poukázky',
          },
          exportImport: {
            title: 'Export / Import poukázek',
          },
        },
        tabs: {
          vouchers: {
            title: 'Přehled poukázek',
          },
        },
      },
      grid: {
        title: 'Emise poukázek',
        filters: {
          name: 'Název',
          code: 'Kód',
          createdDate: 'Vytvořeno',
          emissionGroupId: 'Skupina',
          typeEnum: {
            allOptions: 'Všechny typy',
          },
        },
        actions: {
          create: {
            title: 'Nová emise',
          },
          changeValidity: {
            title: 'Změna platnosti (statická)',
          },
          changeValidityRelative: {
            title: 'Změna platnosti (relativní)',
          },
        },
        advancedFilters: {
          title: 'Filtrovat emise',
          placeholders: {
            name: 'Název',
            code: 'Kód',
            emissionGroupId: 'Všechny skupiny',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvoření nové emise',
        },
        update: {
          title: 'Editace emise',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
          type: 'Vyberte typ poukázky',
          amount: 'Zadejte hodnotu',
          issuer: 'Zadejte vydavatele',
          emissionGroupId: 'Vybertu skupinu emise',
          validFrom: 'Zadejte platnost od',
          validToUseTill: 'Zadejte platnost do',
          technicalArticleNumber: 'Zadejte ID artiklu',
          generateFromNumber: 'Zadejte rozsah od',
          generateQuantity: 'Zadejte počet ks',
          promotionCode: 'Zadejte název promoce',
          maxUsageCount: 'Zadejte max. počet použití',
          maxGenerateQuantity: 'Zadejte max. počet vygenerovaných poukázek',
          addDays: 'Zadejte počet dní',
        },
        popups: {
          generateQuantity: 'Počet poukázek, které se mají vygenerovat (Slouží pro generování poukázek na MW)',
          maxGenerateQuantity: 'Maximální počet poukázek, které se lze vygenerovat',
        },
        common: {
          sections: {
            detail: 'Detail emise',
            configuration: 'Konfigurace emise',
            generation: 'Generování',
          },
        },
        changeValidityRelative: {
          title: 'Změna platnosti (relativní)',
        },
        changeValidity: {
          title: 'Změna platnosti (statická)',
        },
      },
      modalExportImport: {
        title: 'Export / Import poukázek',
        import: {
          title: 'Import',
          description: 'Import poukázek z csv souboru. Pokud emise ještě neobsahuje žádné pokázky, založí je. Pokud již poukázky v emisi existují, pak se u nich pokusí nastavit číslo zákazníka.',
          example: {
            title: 'Příklad',
            value: 'voucherNumber;customerNumber\nV1234;1\nV5678;2\nV1245;',
          },
        },

        export: {
          title: 'Export',
          description: 'Export všech poukázek ve formátu csv.',
        },
      },
      fields: {
        name: 'Název',
        code: 'Kód',
        type: 'Typ poukázky',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Sleva absolutní',
          [VoucherEmissionType.discountPercentage]: 'Sleva procentuální',
        },
        amount: 'Hodnota',
        issuer: 'Vydavatel',
        emissionGroupId: 'Skupina emise',
        validFrom: 'Platnost od',
        validToUseTill: 'Platnost do',
        technicalArticleNumber: 'Číslo technického artiklu',
        generateFromNumber: 'Rozsah od',
        generateQuantity: 'Počet ks',
        generateQuantityEnum: {
          withoutLimit: 'Bez limitu',
        },
        configuration: 'Maska',
        emissionGroup: 'Skupina',
        editedDate: 'Datum editace',
        createdDate: 'Datum vytvoření',
        promotionCode: 'Promoce',
        example: 'Příklad',
        edited: 'Upraveno',
        created: 'Vytvořeno',
        maxUsageCount: 'Max. počet použití jednotlivých poukázek',
        maxUsageCountEnum: {
          withoutRestrictions: 'Bez omezení',
        },
        maxGenerateQuantity: 'Max. počet vygenerovaných poukázek',
        fillValidFrom: 'Vyplnit platnost od',
        fillValidTill: 'Vyplnit platnost do',
        addDays: 'Zadejte počet dní',
        newValidFrom: 'Nová platnost od',
        newValidTill: 'Nová platnost do',
      },
    },
  },
  sk: {
    voucherEmission: {
      detail: {
        title: 'Emisia - {name}',
        actions: {
          generateVouchers: {
            title: 'Vytvoriť poukážky',
          },
          exportImport: {
            title: 'Export / Import poukážok',
          },
        },
        tabs: {
          vouchers: {
            title: 'Prehľad poukážok',
          },
        },
      },
      grid: {
        title: 'Emisie poukážok',
        filters: {
          name: 'Názov',
          code: 'Kód',
          createdDate: 'Vytvorené',
          emissionGroupId: 'Skupina',
          typeEnum: {
            allOptions: 'Všetky typy',
          },
        },
        actions: {
          create: {
            title: 'Nová emisia',
          },
          changeValidity: {
            title: 'Zmena platnosti (statická)',
          },
          changeValidityRelative: {
            title: 'Zmena platnosti (relatívna)',
          },
        },
        advancedFilters: {
          title: 'Filtrovať emisie',
          placeholders: {
            name: 'Názov',
            code: 'Kód',
            emissionGroupId: 'Všetky skupiny',
          },
        },
      },
      form: {
        create: {
          title: 'Vytvorenie novej emisie',
        },
        update: {
          title: 'Editácia emisie',
        },
        placeholders: {
          name: 'Zadajte názov',
          code: 'Zadajte kód',
          type: 'Vyberte typ poukážky',
          amount: 'Zadajte hodnotu',
          issuer: 'Zadajte vydavateľa',
          emissionGroupId: 'Vyberte skupinu emisie',
          validFrom: 'Zadajte platnosť od',
          validToUseTill: 'Zadajte platnosť do',
          technicalArticleNumber: 'Zadajte ID artiklu',
          generateFromNumber: 'Zadajte rozsah od',
          generateQuantity: 'Zadajte počet ks',
          promotionCode: 'Zadajte názov promócie',
          maxUsageCount: 'Zadajte max. počet použití',
          maxGenerateQuantity: 'Zadajte max. počet vygenerovaných poukážok',
          addDays: 'Zadajte počet dní',
        },
        popups: {
          generateQuantity: 'Počet poukážok, ktoré sa majú vygenerovať (Slúži pre generovanie poukážok na MW)',
          maxGenerateQuantity: 'Maximálny počet poukážok, ktoré sa dajú vygenerovať',
        },
        common: {
          sections: {
            detail: 'Detail emisie',
            configuration: 'Konfigurácia emisie',
            generation: 'Generovanie',
          },
        },
        changeValidityRelative: {
          title: 'Zmena platnosti (relatívna)',
        },
        changeValidity: {
          title: 'Zmena platnosti (statická)',
        },
      },
      modalExportImport: {
        title: 'Export / Import poukážok',
        import: {
          title: 'Import',
          description: 'Import poukážok z csv súboru. Ak emisia ešte neobsahuje žiadne poukážky, vytvorí ich. Ak už poukážky v emisii existujú, pokúsi sa nastaviť číslo zákazníka.',
          example: {
            title: 'Príklad',
            value: 'voucherNumber;customerNumber\nV1234;1\nV5678;2\nV1245;',
          },
        },

        export: {
          title: 'Export',
          description: 'Export všetkých poukážok vo formáte csv.',
        },
      },
      fields: {
        name: 'Názov',
        code: 'Kód',
        type: 'Typ poukážky',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Zľava absolutná',
          [VoucherEmissionType.discountPercentage]: 'Zľava percentuálna',
        },
        amount: 'Hodnota',
        issuer: 'Vydavateľ',
        emissionGroupId: 'Skupina emisie',
        validFrom: 'Platnosť od',
        validToUseTill: 'Platnosť do',
        technicalArticleNumber: 'Číslo technického artiklu',
        generateFromNumber: 'Rozsah od',
        generateQuantity: 'Počet ks',
        generateQuantityEnum: {
          withoutLimit: 'Bez limit',
        },
        configuration: 'Maska',
        emissionGroup: 'Skupina',
        editedDate: 'Dátum editácie',
        createdDate: 'Dátum vytvorenia',
        promotionCode: 'Promócia',
        example: 'Príklad',
        edited: 'Upravené',
        created: 'Vytvorené',
        maxUsageCount: 'Max. počet použití jednotlivých poukážok',
        maxUsageCountEnum: {
          withoutRestrictions: 'Bez obmedzení',
        },
        maxGenerateQuantity: 'Max. počet vygenerovaných poukážok',
        fillValidFrom: 'Vyplniť platnosť od',
        fillValidTill: 'Vyplniť platnosť do',
        addDays: 'Zadajte počet dní',
        newValidFrom: 'Nová platnosť od',
        newValidTill: 'Nová platnosť do',
      },
    },
  },
  en: {
    voucherEmission: {
      detail: {
        title: 'Emission - {name}',
        actions: {
          generateVouchers: {
            title: 'Generate vouchers',
          },
          exportImport: {
            title: 'Export / Import vouchers',
          },
        },
        tabs: {
          vouchers: {
            title: 'Vouchers',
          },
        },
      },
      grid: {
        title: 'Voucher emissions',
        filters: {
          name: 'Name',
          code: 'Code',
          createdDate: 'Created',
          emissionGroupId: 'Group',
          typeEnum: {
            allOptions: 'All types',
          },
        },
        actions: {
          create: {
            title: 'New emission',
          },
          changeValidity: {
            title: 'Change validity (static)',
          },
          changeValidityRelative: {
            title: 'Change validity (relative)',
          },
        },
        advancedFilters: {
          title: 'Filter emissions',
          placeholders: {
            name: 'Name',
            code: 'Code',
            emissionGroupId: 'All groups',
          },
        },
      },
      form: {
        create: {
          title: 'Create new emission',
        },
        update: {
          title: 'Edit emission',
        },
        placeholders: {
          name: 'Enter name',
          code: 'Enter code',
          type: 'Select voucher type',
          amount: 'Enter value',
          issuer: 'Enter issuer',
          emissionGroupId: 'Select emission group',
          validFrom: 'Enter valid from',
          validToUseTill: 'Enter valid to',
          technicalArticleNumber: 'Enter technical article number',
          generateFromNumber: 'Enter range from',
          generateQuantity: 'Enter quantity',
          promotionCode: 'Enter promotion name',
          maxUsageCount: 'Enter max. usage count',
          maxGenerateQuantity: 'Enter max. generated voucher count',
          addDays: 'Enter number of days',
        },
        popups: {
          generateQuantity: 'Number of vouchers to generate (Used for generating vouchers on MW)',
          maxGenerateQuantity: 'Max. number of vouchers that can be generated',
        },
        common: {
          sections: {
            detail: 'Emission detail',
            configuration: 'Emission configuration',
            generation: 'Generation',
          },
        },
        changeValidityRelative: {
          title: 'Change validity (relative)',
        },
        changeValidity: {
          title: 'Change validity (static)',
        },
      },
      modalExportImport: {
        title: 'Export / Import vouchers',
        import: {
          title: 'Import',
          description: 'Import vouchers from csv file. If emission does not contain any vouchers yet, it will create them. If vouchers already exist in the emission, it will try to set customer number.',
          example: {
            title: 'Example',
            value: 'voucherNumber;customerNumber\nV1234;1\nV5678;2\nV1245;',
          },
        },

        export: {
          title: 'Export',
          description: 'Export all vouchers in csv format.',
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        type: 'Voucher type',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Discount absolute',
          [VoucherEmissionType.discountPercentage]: 'Discount percentage',
        },
        amount: 'Value',
        issuer: 'Issuer',
        emissionGroupId: 'Emission group',
        validFrom: 'Valid from',
        validToUseTill: 'Valid to',
        technicalArticleNumber: 'Technical article number',
        generateFromNumber: 'Range from',
        generateQuantity: 'Quantity',
        generateQuantityEnum: {
          withoutLimit: 'Without limit',
        },
        configuration: 'Mask',
        emissionGroup: 'Group',
        editedDate: 'Edited date',
        createdDate: 'Created date',
        promotionCode: 'Promotion',
        example: 'Example',
        edited: 'Edited',
        created: 'Created',
        maxUsageCount: 'Max. usage count of vouchers',
        maxUsageCountEnum: {
          withoutRestrictions: 'Without restrictions',
        },
        maxGenerateQuantity: 'Max. generated voucher count',
        fillValidFrom: 'Fill valid from',
        fillValidTill: 'Fill valid to',
        addDays: 'Enter number of days',
        newValidFrom: 'New valid from',
        newValidTill: 'New valid to',
      },
    },
  },
  de: {
    voucherEmission: {
      detail: {
        title: 'Emission - {name}',
        actions: {
          generateVouchers: {
            title: 'Gutscheine erstellen',
          },
          exportImport: {
            title: 'Gutscheine exportieren / importieren',
          },
        },
        tabs: {
          vouchers: {
            title: 'Übersicht der Gutscheine',
          },
        },
      },
      grid: {
        title: 'Emissionen von Gutscheinen',
        filters: {
          name: 'Name',
          code: 'Code',
          createdDate: 'Erstellt am',
          emissionGroupId: 'Gruppe',
          typeEnum: {
            allOptions: 'Alle Typen',
          },
        },
        actions: {
          create: {
            title: 'Neue Emission',
          },
          changeValidity: {
            title: 'Gültigkeit ändern (statisch)',
          },
          changeValidityRelative: {
            title: 'Gültigkeit ändern (relativ)',
          },
        },
        advancedFilters: {
          title: 'Emissionen filtern',
          placeholders: {
            name: 'Name',
            code: 'Code',
            emissionGroupId: 'Alle Gruppen',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Emission erstellen',
        },
        update: {
          title: 'Emission bearbeiten',
        },
        placeholders: {
          name: 'Name eingeben',
          code: 'Code eingeben',
          type: 'Gutscheintyp auswählen',
          amount: 'Wert eingeben',
          issuer: 'Herausgeber eingeben',
          emissionGroupId: 'Gruppe auswählen',
          validFrom: 'Gültig ab',
          validToUseTill: 'Gültig bis',
          technicalArticleNumber: 'Technische Artikelnummer eingeben',
          generateFromNumber: 'Bereich ab',
          generateQuantity: 'Anzahl eingeben',
          promotionCode: 'Promotionsname eingeben',
          maxUsageCount: 'Maximale Anzahl der Einlösungen eingeben',
          maxGenerateQuantity: 'Maximale Anzahl der generierten Gutscheine eingeben',
          addDays: 'Anzahl der Tage eingeben',
        },
        popups: {
          generateQuantity: 'Anzahl der Gutscheine, die generiert werden sollen (Wird zum Generieren von Gutscheinen auf MW verwendet)',
          maxGenerateQuantity: 'Maximale Anzahl der Gutscheine, die generiert werden können',
        },
        common: {
          sections: {
            detail: 'Details zur Emission',
            configuration: 'Einstellungen zur Emission',
            generation: 'Generierung',
          },
        },
        changeValidityRelative: {
          title: 'Gültigkeit ändern (relativ)',
        },
        changeValidity: {
          title: 'Gültigkeit ändern (statisch)',
        },
      },
      modalExportImport: {
        title: 'Gutscheine exportieren / importieren',
        import: {
          title: 'Import',
          description: 'Import von Gutscheinen aus einer CSV-Datei. Wenn die Emission noch keine Gutscheine enthält, werden sie erstellt. Wenn Gutscheine bereits in der Emission vorhanden sind, wird versucht, die Kundennummer zu setzen.',
          example: {
            title: 'Beispiel',
            value: 'voucherNumber;customerNumber\nV1234;1\nV5678;2\nV1245;',
          },
        },

        export: {
          title: 'Export',
          description: 'Export aller Gutscheine im CSV-Format.',
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        type: 'Gutscheintyp',
        typeEnum: {
          [VoucherEmissionType.discountAbsolute]: 'Absoluter Rabatt',
          [VoucherEmissionType.discountPercentage]: 'Prozentualer Rabatt',
        },
        amount: 'Wert',
        issuer: 'Herausgeber',
        emissionGroupId: 'Gruppe der Emission',
        validFrom: 'Gültig ab',
        validToUseTill: 'Gültig bis',
        technicalArticleNumber: 'Technische Artikelnummer',
        generateFromNumber: 'Bereich ab',
        generateQuantity: 'Anzahl',
        generateQuantityEnum: {
          withoutLimit: 'Ohne Limit',
        },
        configuration: 'Maske',
        emissionGroup: 'Emissionsgruppe',
        editedDate: 'Bearbeitungsdatum',
        createdDate: 'Erstellungsdatum',
        promotionCode: 'Promotionsname',
        example: 'Beispiel',
        edited: 'Bearbeitet',
        created: 'Erstellt',
        maxUsageCount: 'Maximale Anzahl der Einlösungen für einzelne Gutscheine',
        maxUsageCountEnum: {
          withoutRestrictions: 'Unbegrenzt',
        },
        maxGenerateQuantity: 'Maximale Anzahl der generierten Gutscheine',
        fillValidFrom: 'Gültigkeit abfüllen',
        fillValidTill: 'Gültigkeit bis abfüllen',
        addDays: 'Anzahl der Tage eingeben',
        newValidFrom: 'Neue Gültigkeit ab',
      },
    },
  },
};
