
export default {
  cs: {
    reportDefinition: {
      detail: {
        title: 'Definice reportu',
      },
      grid: {
        title: 'Definice reportů',
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          code: 'Kód',
        },
        actions: {
          create: {
            title: 'Založit definici reportu',
          },
          bulkDelete: {
            title: 'Hromadné mazání',
            confirm: 'Opravdu si přejete hromadně smazat vybrané záznamy?',
            success: 'Záznamy byly úspěšně smazány',
          },
        },
      },
      form: {
        create: {
          title: 'Nová definice reportu',
        },
        update: {
          title: 'Editace definice reportu',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
          definitionTypeId: 'Vyberte typ definice',
        },
      },
      fields: {
        name: 'Název',
        code: 'Kód',
        configuration: 'Konfigurace',
        definitionTypeId: 'Typ definice',
      },
    },
  },
  sk: {
    reportDefinition: {
      detail: {
        title: 'Definice reportu',
      },
      grid: {
        title: 'Definice reportů',
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          code: 'Kód',
        },
        actions: {
          create: {
            title: 'Založit definici reportu',
          },
          bulkDelete: {
            title: 'Hromadné mazání',
            confirm: 'Opravdu si přejete hromadně smazat vybrané záznamy?',
            success: 'Záznamy byly úspěšně smazány',
          },
        },
      },
      form: {
        create: {
          title: 'Nová definice reportu',
        },
        update: {
          title: 'Editace definice reportu',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
          definitionTypeId: 'Vyberte typ definice',
        },
      },
      fields: {
        name: 'Název',
        code: 'Kód',
        configuration: 'Konfigurace',
        definitionTypeId: 'Typ definice',
      },
    },
  },
  en: {
    reportDefinition: {
      detail: {
        title: 'Report definition',
      },
      grid: {
        title: 'Report definitions',
        filters: {
          search: 'Search (min. 2 characters)',
          code: 'Code',
        },
        actions: {
          create: {
            title: 'Create report definition',
          },
          bulkDelete: {
            title: 'Bulk delete',
            confirm: 'Do you really want to delete selected records in bulk?',
            success: 'Records were successfully deleted',
          },
        },
      },
      form: {
        create: {
          title: 'New report definition',
        },
        update: {
          title: 'Edit report definition',
        },
        placeholders: {
          name: 'Enter name',
          code: 'Enter code',
          definitionTypeId: 'Select definition type',
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        configuration: 'Configuration',
        definitionTypeId: 'Definition type',
      },
    },
  },
  de: {
    reportDefinition: {
      detail: {
        title: 'Report definition',
      },
      grid: {
        title: 'Report definitions',
        filters: {
          search: 'Search (min. 2 characters)',
          code: 'Code',
        },
        actions: {
          create: {
            title: 'Create report definition',
          },
          bulkDelete: {
            title: 'Bulk delete',
            confirm: 'Do you really want to delete selected records in bulk?',
            success: 'Records were successfully deleted',
          },
        },
      },
      form: {
        create: {
          title: 'New report definition',
        },
        update: {
          title: 'Edit report definition',
        },
        placeholders: {
          name: 'Enter name',
          code: 'Enter code',
          definitionTypeId: 'Select definition type',
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        configuration: 'Configuration',
        definitionTypeId: 'Definition type',
      },
    },
  },
};
