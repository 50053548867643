
export default {
  cs: {
    articleList: {
      detail: {
        title: '{name}',
        suitableArticles: {
          title: 'Vyhovující artikly',
          grid: {
            filters: {
              search: 'Vyhledat (min. 2 znaky)',
            },
            columns: {
              text: 'Název',
              internalNumber: 'Interní číslo',
              gtin: 'GTIN',
            },
          },
        },
      },
      grid: {
        title: 'Seznamy artiklů',
        actions: {
          create: {
            title: 'Založit',
          },
          remove: {
            confirm: 'Opravdu chcete smazat seznam "{name}"?',
          },
        },
        filters: {
          search: 'Vyhledat (min. 2 znaky)',
          code: 'Kód',
          isDefaultEnum: {
            allOptions: 'Všechny seznamy',
          },
        },
      },
      form: {
        create: {
          title: 'Nový seznam',
        },
        update: {
          title: 'Úprava seznamu',
        },
        placeholders: {
          name: 'Zadejte název',
          code: 'Zadejte kód',
        },
      },
      fields: {
        name: 'Název',
        code: 'Kód',
        listDefinition: 'Definice seznamu',
        edited: 'Upraveno',
      },
    },
  },
  sk: {
    articleList: {
      detail: {
        title: '{name}',
        suitableArticles: {
          title: 'Vhodné články',
          grid: {
            filters: {
              search: 'Hľadať (min. 2 znaky)',
            },
            columns: {
              text: 'Názov',
              internalNumber: 'Interné číslo',
              gtin: 'GTIN',
            },
          },
        },
      },
      grid: {
        title: 'Zoznam článkov',
        actions: {
          create: {
            title: 'Vytvoriť',
          },
          remove: {
            confirm: 'Naozaj chcete odstrániť zoznam "{name}"?',
          },
        },
        filters: {
          search: 'Vyhľadať (min. 2 znaky)',
          code: 'Kód',
          isDefaultEnum: {
            allOptions: 'Všetky zoznamy',
          },
        },
      },
      form: {
        create: {
          title: 'Nový zoznam',
        },
        update: {
          title: 'Úprava zoznamu',
        },
        placeholders: {
          name: 'Zadajte názov',
          code: 'Zadajte kód',
        },
      },
      fields: {
        name: 'Názov',
        code: 'Kód',
        listDefinition: 'Definícia zoznamu',
        edited: 'Upravené',
      },
    },
  },
  en: {
    articleList: {
      detail: {
        title: '{name}',
        suitableArticles: {
          title: 'Suitable articles',
          grid: {
            filters: {
              search: 'Search (min. 2 characters)',
            },
            columns: {
              text: 'Name',
              internalNumber: 'Internal number',
              gtin: 'GTIN',
            },
          },
        },
      },
      grid: {
        title: 'Article lists',
        actions: {
          create: {
            title: 'Create',
          },
          remove: {
            confirm: 'Do you really want to delete the list "{name}"?',
          },
        },
        filters: {
          search: 'Search (min. 2 characters)',
          code: 'Code',
          isDefaultEnum: {
            allOptions: 'All lists',
          },
        },
      },
      form: {
        create: {
          title: 'New list',
        },
        update: {
          title: 'Update list',
        },
        placeholders: {
          name: 'Enter name',
          code: 'Enter code',
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        listDefinition: 'List definition',
        edited: 'Edited',
      },
    },
  },
  de: {
    articleList: {
      detail: {
        title: '{name}',
        suitableArticles: {
          title: 'Passende Artikel',
          grid: {
            filters: {
              search: 'Suchen (min. 2 Zeichen)',
            },
            columns: {
              text: 'Name',
              internalNumber: 'Interne Nummer',
              gtin: 'GTIN',
            },
          },
        },
      },
      grid: {
        title: 'Artikellisten',
        actions: {
          create: {
            title: 'Erstellen',
          },
          remove: {
            confirm: 'Möchten Sie die Liste "{name}" wirklich löschen?',
          },
        },
        filters: {
          search: 'Suchen (min. 2 Zeichen)',
          code: 'Code',
          isDefaultEnum: {
            allOptions: 'Alle Listen',
          },
        },
      },
      form: {
        create: {
          title: 'Neue Liste',
        },
        update: {
          title: 'Liste aktualisieren',
        },
        placeholders: {
          name: 'Name eingeben',
          code: 'Code eingeben',
        },
      },
      fields: {
        name: 'Name',
        code: 'Code',
        listDefinition: 'Listendefinition',
        edited: 'Bearbeitet',
      },
    },
  },
};
