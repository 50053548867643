export enum VoucherEventOperation {
  creation = 'Creation',
  cancellation = 'Cancellation',
  redemption = 'Redemption',
  manualChange = 'ManualChange',
  automatedCreation = 'AutomatedCreation',
  automatedActivation = 'AutomatedActivation',
  automatedCancellation = 'AutomatedCancellation',
  return = 'Return',
}
