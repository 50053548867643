import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/report-definitions',
    name: 'report-definitions',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.reportDefinitionList,
    },
  },
  {
    path: '/report-definition/create',
    name: 'report-definition-create',
    component: () => import('./Form/CreatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.reportDefinitionCreate,
    },
  },
  {
    path: '/report-definition/update/:id',
    name: 'report-definition-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
] as RegisterRoutesOptions;
