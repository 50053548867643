import {AclKey} from '@api/posMiddlewareCore/acl/acl';
import {RegisterRoutesOptions} from '@ui/plugins/modules';

export default [
  {
    path: '/article-lists',
    name: 'article-lists',
    component: () => import('./Grid/GridPage.vue'),
    meta: {
      aclKeyForAccess: AclKey.articleListList,
    },
  },
  {
    path: '/article-list/update/:id',
    name: 'article-list-update',
    component: () => import('./Form/UpdatePage.vue'),
    props: true,
  },
  {
    path: '/article-list/create',
    name: 'article-list-create',
    component: () => import('./Form/CreatePage.vue'),
    meta: {
      aclKeyForAccess: AclKey.articleListCreate,
    },
  },
] as RegisterRoutesOptions;
